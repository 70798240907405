<template>
  <div class="pop">
    <el-tabs v-model="activeName">
      <el-tab-pane
        label="成人"
        name="adult"
      >
        <template v-if="isLuggage">
          <div class="pop-t">
            <span class="pop-t-gap"></span>
            行李规定
          </div>
          <Luggage :luggages='adtLuggages' />
        </template>
        <div class="item1" style="display: block">
          <div class="pop-t">
            <span class="pop-t-gap"></span>
            退改签规则
          </div>
          <el-table
            class="table"
            border
            align="center"
            :data="adultFees"
            v-if="adultFees != undefined && adultFees.length != 0"
          >
            <el-table-column
              width="203"
              label="变更时段"
              prop="changeTime"
            >
              <template slot-scope="scope">
                <span :class="scope.row.expired ? 'delete-line' : ''">{{ scope.row | dateDesc }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width="104"
              label="退票退费"
              prop="refundFee"
            >
              <template slot-scope="scope">
                <span :class="scope.row.expired ? 'delete-line' : ''">{{ scope.row.childRefundCharge | refundChargeDesc }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width="104"
              label="改期收费"
              prop="changeFee"
            >
              <template slot-scope="scope">
                <span :class="scope.row.expired ? 'delete-line' : ''">{{ scope.row.childChangeCharge | changeChargeDesc }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="签转"
              prop="change"
            ><template><span>不允许签转</span></template></el-table-column>
          </el-table>
          <div
            class="item2"
            v-if="adultFees == undefined || adultFees.length <= 0"
          >
            退改以航司客规为准
          </div>
        </div>
        <div
          style="line-height:25px;"
          v-html="refundTips"
        ></div>
      </el-tab-pane>
      <el-tab-pane
        label="儿童"
        name="child"
      >
        <template  v-if="isLuggage">
          <div class="pop-t">
            <span class="pop-t-gap"></span>
            行李规定
          </div>
          <Luggage :luggages='chdLuggages'/>
        </template>
        <div class="item1" style="display: block">
          <div class="pop-t">
            <span class="pop-t-gap"></span>
            退改签规则</div>
          <el-table
            class="table"
            border
            :data="chdFees"
            v-if="chdFees != undefined && chdFees.length != 0"
          >
            <el-table-column
              width="203"
              label="变更时段"
              prop="changeTime"
            >
              <template slot-scope="scope">
                <span :class="scope.row.expired ? 'delete-line' : ''">{{ scope.row | dateDesc }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width="104"
              label="退票退费"
              prop="refundFee"
            >
              <template slot-scope="scope">
                <span :class="scope.row.expired ? 'delete-line' : ''">{{ scope.row.childRefundCharge | refundChargeDesc }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width="104"
              label="改期收费"
              prop="changeFee"
            >
              <template slot-scope="scope">
                <span :class="scope.row.expired ? 'delete-line' : ''">{{ scope.row.childChangeCharge | changeChargeDesc }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="签转"
              prop="change"
            ><template><span>不允许签转</span></template></el-table-column>
          </el-table>
          <div
            class="item2"
            v-if="chdFees == undefined || chdFees.length <= 0"
          >
            退改以航司客规为准
          </div>
        </div>
        <div
          style="line-height:25px;"
          v-html="refundTips"
        ></div>
        <!-- <div class="item2">
            2.购买当天起飞航班客票，须30分钟内完成支付出票，超出时限取消座位，湖南航空不承担责任 。
          </div>
          <div class="item3">
            3.网站专享优惠特价请即时支付出票，未即时出票超出时限被取消座位，湖南航空不承担责任 。
          </div>
          <div class="item4">
            4.儿童、婴儿旅客不得单独乘机，改期时请确认与成人行程一致。
          </div>
          <div class="item5">
            *此页面查询结果仅供参考，预订成功与否请以实际支付出票为准。
          </div> -->
      </el-tab-pane>
      <el-tab-pane
        label="婴儿"
        name="baby"
        v-if="showInf"
      >
        <div class="item1">
          <div>1.</div>
          <el-table
            class="table"
            border
            :data="chdFees"
          >
            <el-table-column
              width="203"
              label="变更时段"
              prop="changeTime"
            ></el-table-column>
            <el-table-column
              width="104"
              label="退票退费"
              prop="refundFee"
            ></el-table-column>
            <el-table-column
              width="104"
              label="改期收费"
              prop="changeFee"
            ></el-table-column>
            <el-table-column
              label="签转"
              prop="change"
            ></el-table-column>
          </el-table>
        </div>
        <div class="item2">
          2.购买当天起飞航班客票，须30分钟内完成支付出票，超出时限取消座位，湖南航空不承担责任 。
        </div>
        <div class="item3">
          3.网站专享优惠特价请即时支付出票，未即时出票超出时限被取消座位，湖南航空不承担责任 。
        </div>
        <div class="item4">
          4.儿童、婴儿旅客不得单独乘机，改期时请确认与成人行程一致。
        </div>
        <div class="item5">
          *此页面查询结果仅供参考，预订成功与否请以实际支付出票为准。
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import moment from "moment";
import { queryDict } from "@/api/newsapi";
import Luggage from "./Luggage";
export default {
  components: {
    Luggage
  },
  props: {
    cabinUnit: {
      type: Object,
      defualt: {}
    },
    isLuggage: {
      type: Boolean,
      defualt: false
    }
  },
  data() {
    return {
      activeName: "adult",
      adultFees: [],
      showInf: false,
      chdFees: [],
      // desc: ['航班起飞endTime（含）之前', '航班起飞startTime至航班起飞endTime（含）之前', '航班起飞startTime之后']
      desc: ["endTime（含）前", "startTime至endTime（含）", "startTime后"],
      refundTips: "",
      chdLuggages: [],
      adtLuggages:[]
    };
  },
  watch: {
    cabinUnit: {
      handler(newUnit, oldUnit) {
        this.loadRules();
        this.loadLuggage();
      }
    }
  },
  filters: {
      dateDesc(data) {
          var text = "";
          if (!data.startDateTime) {
              text = `${data.endDateTime}（含）前`;
          } else if (!data.endDateTime) {
              text = `${data.startDateTime} 后`;
          } else {
              text = `${data.startDateTime} 至 ${data.endDateTime}（含）`;
          }
          return text;
      },
      refundChargeDesc(val) {
          if (isNaN(val)) {
              return "不可退";
          } else {
              return `￥${val}/人`;
          }
      },
      changeChargeDesc(val) {
          if (isNaN(val)) {
              return "不可改期";
          } else {
              return `￥${val}/人`;
          }
      }
  },
  mounted() {
    this.loadRules();
    this.loadLuggage();
    this.getDicConfigByKey('CHANGE_REFUND_FEE_REMARK');
  },
  methods: {
    async getDicConfigByKey(key) {
      queryDict({ key: key }).then(res => {
        if (res.data) {
          this.refundTips = res.data.replace(/\\n/g, "<br/>")
        }
      });
    },
    loadLuggage(){
      var segment = this.cabinUnit;
      if (!segment) return;
      segment.adtBaggageGroup && (this.adtLuggages = segment.adtBaggageGroup.baggageList)
      segment.chdBaggageGroup && (this.chdLuggages = segment.chdBaggageGroup.baggageList)
    },
    loadRules() {
      var segment = this.cabinUnit;
      if (!segment) return;
      var adultFees = (segment.changeAndRefundRuleVO && segment.changeAndRefundRuleVO.changeAndRefundRuleInfoList) || [];
      if (adultFees.find(t => t.adultRefundCharge == null || t.adultChangeCharge == null)) {
          this.adultFees = [];
      } else {
          this.adultFees = adultFees;
      }
      var chdFees = (segment.changeAndRefundRuleVO && segment.changeAndRefundRuleVO.changeAndRefundRuleInfoList) || [];
      if (chdFees.find(t => t.childRefundCharge == null || t.childChangeCharge == null)) {
          this.chdFees = [];
      } else {
          this.chdFees = chdFees;
      }
    },
    getDesc(index, startTime, endTime, lastIndex, depDatetime) {
      let replaceStartTime = null;
      let replaceEndTime = null;
      replaceStartTime = this.transDate(
        depDatetime + startTime * 60 * 60 * 1000
      );
      replaceEndTime = this.transDate(depDatetime + endTime * 60 * 60 * 1000);
      let text = null;
      if (index == 0) {
        text = this.desc[0];
        text = text.replace(/endTime/, replaceEndTime);
      } else if (index == lastIndex) {
        text = this.desc[2];
        text = text.replace(/startTime/, replaceStartTime);
      } else {
        text = this.desc[1];
        text = text.replace(/startTime/, replaceStartTime);
        text = text.replace(/endTime/, replaceEndTime);
      }
      return text;
    },
    transDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
    transPrice(percent, type) {
      let price =
        type == "adult"
          ? this.cabinUnit.salePrice
          : this.cabinUnit.childSalePrice;
      return Number(price * (percent / 100)).toFixed(0);
    },
    compareDate(startTime, endTime, depDatetime) {
      let curTime = new Date().getTime();
      return curTime > depDatetime + endTime * 60 * 60 * 1000;
    }
  }
};
</script>
<style>
.pop .el-tab-pane > div {
  display: flex;
  padding: 6px 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65);
}
.pop .table {
  font-size: 13px !important;
}
.pop .el-table table td,
.pop .el-table table th {
  padding: 6px !important;
}
.pop .tabs-bar {
  border-bottom: 1px solid #e8e8e8;
  margin: 0 0 16px;
  outline: none;
}
.pop .el-table .cell {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}
.pop .el-tabs__item {
  padding: 8px 16px !important;
  /* margin-right: 32px; */
  height: auto;
  line-height: normal;
}
.pop .el-tabs__item:hover {
  color: #f87374;
}
.pop .el-popover {
  padding: 12px 16px !important;
}
.pop .el-tabs__nav-wrap::after {
  height: 1px;
}
.pop .el-tabs__active-bar {
  width: 28px !important;
  background-color: #f87374;
}
.el-tabs__item.is-active {
  color: #f87374;
}
.delete-line {
  text-decoration: line-through;
  color: #ccc;
}
.luggage{
  display: flex;
}
.luggage-left, .luggage-right{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.luggage-right-img{
  width: 203px;
  height: 170px;
}
.luggage-left-img{
  width: 215px;
  height: 216px;
}
.luggage-info-t{
  font-weight: bold;
}
.pop-t{
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  /* justify-content: ; */
}
.pop-t-gap{
  width: 4px;
  height: 20px;
  background: #d70039;
  margin-right: 5px;
  display: inline-block;
}
</style>